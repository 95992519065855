@import url(./normalize.css);
@import url(./tokens.css);

@font-face {
  font-family: "Space Mono";
  src: url(../fonts/Space_Mono/SpaceMono-Regular.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Space Mono";
  src: url(../fonts/Space_Mono/SpaceMono-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: "Space Grotesk";
  src: url(../fonts/Space_Grotesk/SpaceGrotesk-VariableFont_wght.ttf)
    format("truetype");
  font-weight: 300 700;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
  background: #111;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ddd;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--light-gray);
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--light-gray);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--light-gray);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a,
a:visited {
  color: #fff;
}

a:hover,
a:active {
  color: var(--chartreuse);
}

button {
  cursor: pointer;
}

hr {
  background: var(--light-gray);
  border: none;
  flex-flow: row;
  place-content: center space-between;
  align-items: center;
  height: 4px;
  width: 100%;
}

html,
body {
  margin: auto;
  padding: 0;
  height: 100%;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  width: 100vw;
  background-color: var(--black);
  background-image: url(../background.png);
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Space Grotesk", sans-serif;
  font-size: 1.0256410256410258vw;
  color: #fff;
  position: relative;
  overflow-y: none;
  overflow-x: hidden;
}

h2 {
  color: #fff;
  font-family: "Space Grotesk", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}

h3 {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  font-family: "Space Mono", monospace;
  font-style: normal;
  font-size: 18px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  /* -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px); */
  z-index: 10;
  border-radius: 100%;
}

.hide {
  display: none !important;
}

.hippo {
  width: auto;
  height: 32px;
}

.hippo-loading {
  width: auto;
  height: 64px;
}

input[type="submit"],
button.large {
  color: #fff;
  background: transparent;

  text-align: center;
  font-family: "Space Mono", monospace;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  text-transform: uppercase;

  width: 234px;
  height: 63px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #fff;

  animation-timing-function: linear;
  animation-duration: 300ms;
}

input[type="submit"]:hover,
button.large:hover {
  color: #000;
  border: solid 1px #000;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  border-radius: 8px;
  background: var(--chartreuse);
  animation-timing-function: linear;
  animation-duration: 800ms;
}

input[type="text"] {
  outline: none;
  color: #fff;
  font-family: "Space Grotesk", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 26px */
}

.loader {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 1;
  pointer-events: none;
  z-index: 100;
}

#no-more-secrets {
  cursor: default;
  position: fixed;
  bottom: 15px;
  left: 15px;
  z-index: 9999999999;

  color: var(--light-gray);
  font-family: "Space Mono", monospace;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  text-transform: uppercase;
  cursor: pointer;
}

.page {
  display: block;
  margin: auto;

  z-index: 33;
  position: relative;
}

.pane {
  display: none;
  position: absolute;
  top: 8px;
  right: 8px;
  box-shadow: 0 0 40px black;
  z-index: 1000;
  overflow: scroll;
  max-height: 80%;
}
/* video::-webkit-media-controls-play-button { */
/* display: none; */
/* } */

/* video::-webkit-media-controls-volume-slider { */
/* display: none; */
/* } */

/* video::-webkit-media-controls-mute-button { */
/* display: none; */
/* } */

/* video::-webkit-media-controls-timeline { */
/* display: none; */
/* } */

/* video::-webkit-media-controls-current-time-display { */
/* display: none; */
/* } */

/* video::-webkit-media-controls-fullscreen-button { */
/* } */

section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  gap: 2em;
}

.spacer-full {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.spacer-half {
  height: 50vh;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  mix-blend-mode: plus-lighter;
}

#terms,
#cookies,
#privacy {
  width: 100vw !important;
  position: relative;
  margin-top: var(--content-margin-top);
  margin-bottom: var(--content-margin-bottom);
  max-width: var(--max-layout-width);
  display: grid;
  grid-template-columns: var(--grid-column);
  grid-template-rows: auto;
  gap: var(--grid-column-gap);
  padding: 34px 24px 22px 24px;
  width: calc(100%-48px);
}

iframe[data-testid="policy-iframe"] {
  width: 100vw !important;
  height: 100vh !important;
  display: flex !important;
  position: relative !important;
  overflow-y: scroll;
}

.image-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 90%;
  height: auto;
  border-radius: 8px;
  padding: 40px 16px 16px 16px;
  margin-left: -64px;
  gap: 8px;
  border: 1px solid var(--white-10, rgba(255, 255, 255, 0.1));
  background: radial-gradient(
    92.57% 92.57% at 50% 32.49%,
    rgba(17, 17, 17, 0.09) 0%,
    rgba(17, 17, 17, 0) 100%
  );
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  z-index: 1;
}

.image-container:last-of-type {
  align-items: flex-start;
  margin-left: 40px;
  margin-top: -16vh;
  z-index: 0;
}

.image-container .label {
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
}

.image-inside {
  position: relative;
  width: 100%;
  height: 30vh;
  background-image: url(../work.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.image-inside-personal {
  background-image: url(../personal.png);
}

#request {
  position: relative;
  margin-top: var(--content-margin-top);
  margin-bottom: var(--content-margin-bottom);
  max-width: var(--max-layout-width);
  display: grid;
  grid-template-columns: var(--grid-column);
  grid-template-rows: auto;
  gap: var(--grid-column-gap);
  padding: 34px 24px 22px 24px;
  width: calc(100%-48px);
}

#request .real-input {
  display: none;
  opacity: 0;
}

#request .request-form input[type="text"] {
  width: 100%;
  border-bottom: solid 1px var(--light-gray);
}

#request .request-form input[type="email"] {
  width: 100%;
  border-bottom: solid 1px var(--light-gray);
}

#request .request-images {
  position: relative;
  width: 100%;
  height: 100%;
}

#request-access-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 64px;
}

#request .access-submit-holder {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}

#request-access-done {
  opacity: 0.95;
  background: var(--chartreuse);
  -webkit-backdrop-filter: blur(37px);
  backdrop-filter: blur(37px);
  border-radius: 8px;
  flex-shrink: 0;
  width: 559px;
  height: 593px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.request-complete-icon {
  stroke-width: 2px;
  stroke: #000;
  flex-shrink: 0;
  width: 53.107px;
  height: 53.107px;
  margin-bottom: 20px;
  animation-duration: 0.8s;
  animation-timing-function: linear;
}

.request-complete-body {
  width: 318px;
  color: var(--black);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Space Grotesk", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 39px */
}

.request-form {
  position: relative;
  width: 100%;
}

.request-form form {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  gap: 24px;
}

.request-form .request-title {
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: "Space Grotesk", sans-serif;
  font-size: 2em;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 31.2px */
}

.request-form label {
  margin-top: 20px;
  display: flex;
  width: 129px;
  height: 19.246px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--light-gray);
  font-family: "Space Mono", monospace;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  text-transform: uppercase;
}

.request-form input[type="text"],
.request-form input[type="email"] {
  outline: none;
  color: #fff;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  width: 100% !important;
  height: 32px;
  font-family: Space Grotesk;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  background: transparent;
  border: none;
  padding: 10px 0;
}

.request-form input[type="submit"] {
  display: block;
  cursor: pointer;
}

/* __ANIMATION STUFF__ */

.animated {
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.repeat-1 {
  animation-iteration-count: var(--animate-repeat);
}

.animated.repeat-2 {
  animation-iteration-count: calc(var(--animate-repeat) * 2);
}

.animated.repeat-3 {
  animation-iteration-count: calc(var(--animate-repeat) * 3);
}

.animated.delay-1s {
  animation-delay: var(--animate-delay);
}

.animated.delay-2s {
  animation-delay: calc(var(--animate-delay) * 2);
}

.animated.delay-3s {
  animation-delay: calc(var(--animate-delay) * 3);
}

.animated.delay-4s {
  animation-delay: calc(var(--animate-delay) * 4);
}

.animated.delay-5s {
  animation-delay: calc(var(--animate-delay) * 5);
}

.animated.faster {
  animation-duration: calc(var(--animate-duration) / 2);
}

.animated.fast {
  animation-duration: calc(var(--animate-duration) * 0.8);
}

.animated.slow {
  animation-duration: calc(var(--animate-duration) * 2);
}

.animated.slower {
  animation-duration: calc(var(--animate-duration) * 3);
}

@media print, (prefers-reduced-motion: reduce) {
  .animated {
    animation-duration: 1ms !important;
    transition-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }

  .animated[class*="Out"] {
    opacity: 0;
  }
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.bounceIn {
  animation-duration: calc(var(--animate-duration) * 0.75);
  animation-name: bounceIn;
}

.founder-container {
  flex-wrap: wrap;
  gap: 48px;
  display: flex;
  position: relative;
  justify-content: space-around;
}

.founder-info {
  color: #fff;
  font-family: "Space Grotesk", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 27px */
  z-index: 1;
  position: relative;
}

.founder-image {
  z-index: 0;
  position: absolute;
  display: none;
  width: 70%;
  height: 70%;
  background-repeat: no-repeat;
  background-size: contain;
}

.kaba-chr {
  bottom: -50%;
  left: -5%;
  background-image: url(../founders/christina.jpg);
}

.founder-dus {
  bottom: -50%;
  right: -20%;
  background-image: url(../founders/founder-dus.png);
}

.founder-nate {
  top: -50%;
  left: -5%;
  background-image: url(../founders/founder-nate.png);
}

.human {
  width: 45vw;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.human:last-of-type {
  opacity: 0;
}

.human:hover .founder-image {
  display: inline-block;
  opacity: 0.8;
}

#who-we-are {
  margin-top: var(--content-margin-top);
  margin-bottom: var(--content-margin-bottom);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 112px;
  position: relative;
  overflow: hidden;
  padding: 34px 24px 22px 24px;
  width: calc(100%-48px);
  max-width: var(--max-layout-width);
  overflow: visible;
}

#who-we-are h4 {
  color: #fff;
  font-family: "Space Grotesk", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 39px */
  margin-bottom: 10px;
  z-index: 1;
  position: relative;
}

#who-we-are .section-copy {
  color: #fff;
  text-align: center;
  font-family: "Space Grotesk", sans-serif;
  font-size: 1.65em;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5em;
  width: 55vw;
}

@media (min-width: 2000px) {
  /* WELCOME */
  .preview-omnibar-text {
    font-size: 0.8rem;
  }

  .preview-omnibar {
    height: 500px !important;
    margin-bottom: 21vh;
  }

  /* TEAM */
  .human {
    width: 35vw;
  }

  /* ACCESS */
  .request-form .request-title {
    font-size: 1.4em;
  }

  video#kaba-main-video {
    margin-top: 60px;
    max-height: 1000px !important;
  }
}

@media (min-width: 1700px) {
  /* WELCOME */
  .preview-omnibar-text {
    font-size: 1rem;
    margin-left: 0 !important;
  }

  .preview-omnibar {
    height: 369px;
    margin-bottom: 16vh;

    transform: scale(1);
  }

  /* TEAM */
  .human {
    width: 35vw;
  }

  /* ACCESS */
  .request-form .request-title {
    font-size: 1.4em;
  }
}

@media (min-width: 1029px) {
  /* WELCOME */
  .preview-omnibar-text {
    font-size: 1rem;
    margin-left: 0 !important;
  }

  .preview-omnibar {
    margin-bottom: 2vh;
    margin-left: 0%;
    transform: scale(0.8);
  }

  /* TEAM */
  .human {
    width: 35vw;
  }

  /* ACCESS */
  .request-form .request-title {
    font-size: 1.4em;
  }
}

@media (max-width: 1028px) {
  /* WELCOME */
  .value-prop-title {
    max-width: calc(var(--circle-copy-width) * 1.3);
    font-size: 2em;
  }

  .value-prop-sub-title {
    max-width: calc(var(--circle-copy-width) * 1.3);
    font-size: 2.75em;
  }

  .value-prop-body {
    max-width: calc(var(--circle-copy-width) * 1.3);
    font-size: 2.5em;
  }

  .preview-omnibar {
    width: 55vw;
    margin-bottom: 3vh;
    transform: scale(0.8);
  }

  .preview-omnibar-bottom h5 {
    font-size: 1.1em;
  }

  .preview-omnibar-idea {
    width: 20vw;
    font-size: 1em;
  }

  .preview-omnibar-bottom p {
    font-size: 1.2em !important;
  }

  .preview-omnibar-text {
    font-size: 1.5rem;
  }

  /* FOOTER */
  footer .kaba-info-copy p {
    font-size: 0.9em;
  }

  footer .content-container {
    /* grid-template-columns: 0.55fr 0.45fr; */
  }

  /* TEAM */
  .human {
    width: 43vw;
  }

  #who-we-are .section-copy {
    font-size: 2em;
    width: 60vw;
  }

  /* CONTACT */
  #contact .kaba-contact-message {
    max-width: 80%;
  }

  /* ACCESS */
  #request {
    grid-template-columns: 0.55fr 0.45fr;
  }
}

@media (max-width: 900px) {
  .preview-omnibar {
    width: 65vw;
    margin-bottom: 0vh;
    transform: scale(0.7);
  }
}

@media (max-width: 800px) {
  /* WELCOME */
  .preview-omnibar {
    width: 75vw;
    transform: scale(0.5);
    margin-bottom: -11vh;
  }

  .preview-omnibar-bottom h5 {
    font-size: 1.5em;
  }

  .preview-omnibar-idea {
    width: 20vw;
    font-size: 1.2em;
  }

  .preview-omnibar-bottom p {
    font-size: 1.7em !important;
  }

  .preview-omnibar-text {
    font-size: 1.7rem;
  }

  /* TEAM */
  .human {
    width: var(--mobile-content-width);
  }
}

@media (max-width: 756px) {
  #s-value-props {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  #s-value-props section {
    position: relative;
    width: auto;
    left: 0;
    margin-top: 0;
    margin-bottom: 13vh;
  }

  .value-prop-progress-container {
    display: none;
  }

  .value-prop-progress-container-mobile {
    display: flex;
    margin-top: 25px;
  }
}

@media (max-width: 500px) {
  video#kaba-main-video {
    max-width: 90% !important;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .kaba-mission {
    line-height: 2em !important;
  }
  /* WELCOME */
  .preview-omnibar {
    display: none;
  }

  .preview {
    width: 90%;
    height: 35vh;
    background-image: url(../preview-sm.png);
  }

  #s-one h1 {
    font-size: 7em;
  }

  #s-one p {
    font-size: 3em;
    max-width: 75%;
  }

  #s-two p {
    font-size: 4.5em;
    max-width: 75%;
    margin-bottom: 4vh;
  }

  .value-prop-title {
    margin-top: 5vh;
    max-width: calc(var(--circle-copy-width) * 2.2);
    font-size: 4em;
  }

  .value-prop-sub-title {
    max-width: calc(var(--circle-copy-width) * 2.2);
    font-size: 5em;
  }

  .value-prop-body {
    max-width: calc(var(--circle-copy-width) * 2.2);
    font-size: 4em;
  }

  #s-last p {
    font-size: 4.2em;
    max-width: 75%;
  }

  .section-split {
    padding-bottom: 60px;
  }

  #overview {
    gap: 40px;
  }

  .overview-section {
    flex: auto;
    padding: 0 25px;
  }

  #kaba-nav-items button .status {
    bottom: -10px;
  }

  /* FOOTER */
  footer .kaba-info {
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 4vh;
    gap: 32px;
  }

  footer .kaba-info-copy {
    align-items: center;
  }

  footer .kaba-info-copy p {
    text-align: center;
  }

  footer .kaba-copyright {
    flex-direction: column;
    gap: 8px;
  }

  footer .kaba-info .content-left {
    flex-direction: column;
    align-items: center;
    flex-direction: column-reverse;
    gap: 32px;
  }

  footer .content-container {
    display: flex;
    flex-direction: column-reverse;
  }

  footer .socials a img {
    width: 24px;
  }

  /* TEAM */
  #who-we-are .section-copy {
    font-size: 4em;
    width: var(--mobile-content-width);
  }

  #who-we-are {
    margin-top: calc(var(--content-margin-top) * 0.75);
    margin-bottom: calc(var(--content-margin-bottom) * 0.5);
  }

  .founder-image {
    display: block;
    position: initial;
    width: 150px;
    height: 150px;
    margin: 0 auto 15px;
  }

  .founder-nate ~ .founder-info {
    margin-bottom: 25px;
  }

  /* CONTACT */
  #contact {
    margin-top: calc(var(--content-margin-top) * 0.75);
    margin-bottom: calc(var(--content-margin-bottom) * 0.5);
    display: flex;
    flex-direction: column;
    padding: 0 24px;
  }

  #contact .kaba-contact-message {
    max-width: 90%;
  }

  /* ACCESS */
  .image-container {
    margin-left: 0;
  }

  .image-container:last-of-type {
    margin-left: 0;
    margin-top: 0;
  }

  #request {
    margin-top: calc(var(--content-margin-top) * 0.75);
    margin-bottom: calc(var(--content-margin-bottom) * 0.5);
    display: flex;
    flex-direction: column-reverse;
    padding: 0 24px;
  }

  #request .access-submit-holder {
    justify-content: center;
  }

  #request .request-images {
    width: 100vw;
    padding: 24px 24px 0 24px;
    display: flex;
    flex-direction: column;
    gap: 64px;
  }

  .request-form .request-title {
    font-size: 4em;
  }

  /* NAV */
  #button-home {
    display: inline !important;
  }

  #kaba-nav-items {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 32px;
  }

  nav {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 100%;
  }

  .nav-container {
    flex-direction: column;
    padding: 32px 0;
    width: 100%;
    margin: 0;
    align-items: center;
  }

  .nav-container .home span {
    display: none;
  }

  .section-split div {
    font-size: 4em;
  }
  .section-split h1 {
    font-family: Space Grotesk;
    font-size: 5.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }
}

/* @media (min-height: 600px) { */
/* #s-one { */
/* margin-top: 10px; */
/* gap: 15px; */
/* } */
/* video#kaba-main-video { */
/* margin-top: 30px; */
/* max-height: 350px !important; */
/* margin-bottom: 50px; */
/* } */
/* } */

@media (max-height: 650px) and (orientation: landscape) {
  /* WELCOME */
  .value-prop-title {
    margin-top: 0;
    max-width: calc(var(--circle-copy-width) * 0.5);
    font-size: 0.9em;
  }

  .value-prop-sub-title {
    max-width: calc(var(--circle-copy-width) * 0.5);
    font-size: 1.1em;
  }

  .value-prop-body {
    max-width: calc(var(--circle-copy-width) * 0.5);
    font-size: 1em;
  }

  .value-prop {
    gap: 16px !important;
  }

  .preview-omnibar-text {
    margin-top: 1.5%;
    margin-left: 24%;
    transform: scale(0.8);
    transform-origin: left;
  }

  video#kaba-main-video {
    margin-top: 10px;
    height: 300px;
  }

  .section-split div {
    font-size: 4em;
  }
  .section-split h1 {
    font-family: Space Grotesk;
    font-size: 5.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }
}

@media screen and (max-height: 1000px) {
  @media screen and (min-width: 1000px) {
    #s-one {
      margin-top: 10px;
      gap: 15px;
    }
    video#kaba-main-video {
      /* margin-top: 30px; */
      max-height: 350px !important;
      width: auto;
      /* margin-bottom: 50px; */
    }
  }
}

@media screen and (max-height: 650px) {
  @media screen and (min-width: 1000px) {
    video#kaba-main-video {
      display: none;
    }
  }
}

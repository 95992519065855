footer {
  color: #fff;
  letter-spacing: -0.4px;
  background: linear-gradient(#0005, 10%, #000e);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  margin: auto;
  font-family:
    Space Grotesk,
    sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 200;
  line-height: 140%;
  display: flex;
  z-index: 33;
  position: relative;
}

footer a,
a:visited {
  color: var(--light-gray);
}

footer a:hover,
a:active {
  color: var(--chartreuse);
}

footer .content-container {
  display: grid;
  /* grid-template-columns: var(--grid-column); */
  /* grid-template-rows: auto; */
  /* gap: var(--grid-column-gap); */
  padding: 34px 25px 22px;
  width: 100%;
  max-width: var(--max-layout-width);
  justify-content: space-evenly;
}

footer div.footer-kaba-logo {
  transform: scale(1.4);
  transform-origin: left -40%;
}

footer form {
  margin-top: 24px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

footer .kaba-copyright {
  color: var(--light-gray);
  display: flex;

  /* Mono/Small/Regular - 12 */
  font-family: "Space Mono", monospace;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.28px;
  text-transform: uppercase;
}

footer .kaba-info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
}

footer .kaba-info .content-left {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 16px;
}

footer .kaba-info-copy {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  max-width: 75%;
}

footer hr {
  background: var(--light-gray);
  width: 100%;
  height: 1px;
}

footer form input {
  outline: none;
  color: #fff;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  width: 100% !important;
  height: 32px;
  font-family: Space Grotesk;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  background: transparent;
  border: none;
  padding: 10px 0;
}

footer label {
  color: var(--light-gray);
  font-family: "Space Mono", monospace;
  font-size: 0.8em;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  text-transform: uppercase;
}

footer .legal-links {
  margin-left: 16px;
}

footer .signup {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: solid 1px var(--light-gray);
}

footer .signup p {
  color: #fff;
  font-family: "Space Grotesk", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
  letter-spacing: -0.4px;
  margin-bottom: 10px;
}

footer .socials {
  margin-top: 4px;
  gap: 24px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

footer .socials a img {
  width: 16px;
}

.sign-up-confirm {
  position: relative;
  background-color: var(--chartreuse);
  color: var(--black);
  padding: 16px 24px;
  border-radius: 8px;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  font-size: 1em;
  font-weight: 400;
  gap: 16px;
}

.sign-up-confirm svg {
  margin-top: -10px;
  margin-left: -10px;
  transform: scale(0.5);
}

.submit-icon {
  background-image: url(../submit.png);
  background-size: contain;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  bottom: 8px;
  right: 0;
  width: 32px;
  height: 32px;
  border: none;
  outline: none;
}

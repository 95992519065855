nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
  margin: auto;
  max-width: var(--max-layout-width);
}

.nav-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 34px 24px 22px 24px;
  width: 100%;
  margin: auto;
  max-width: var(--max-layout-width);
}

#kaba-nav-items {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

nav.fill {
  border-radius: 0px 0px 5px 5px;
  border: solid 1px #ffffff0f;
  box-shadow:
    0 1px 1px rgb(0 0 0 / 46%),
    0 2px 2px rgb(0 0 0 / 48%);
  background-color: #00000061;
  border-top: none;
}

.nav-container .home {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-container .home span {
  margin-left: 20px;
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.42px;
  letter-spacing: 0.1em;
  text-align: left;
}

.open {
  border: 1px solid var(--light-gray);
  border-top: none;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  background-color: rgba(0, 0, 0, 0.8);
  -webkit-backdrop-filter: saturate(280%) blur(10px);
  backdrop-filter: saturate(280%) blur(10px);

  #kaba-nav-items {
    display: flex;
  }

  .home {
    display: none;
  }
}

#kaba-nav-items button {
  font-family: "Space Mono", monospace;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: center;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  animation-timing-function: linear;
  animation-duration: 800ms;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  position: relative;
}

#kaba-nav-items #button-home {
  display: none;
}

.home:hover span,
.home:hover svg path {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  color: var(--chartreuse);
  fill: var(--chartreuse);
}

#kaba-nav-items button.active,
#kaba-nav-items button:hover {
  color: var(--chartreuse);
}

#kaba-nav-items button .status {
  display: none;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: var(--chartreuse);
  position: absolute;
  bottom: -12px;
}

#kaba-nav-items button.active .status,
#kaba-nav-items button:hover .status {
  display: block;
}

#overview {
  text-align: center;
  flex-flow: column wrap;
  gap: 90px;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 130px;
}

.overview-item {
}

.overview-visual {
  width: 400px;
  height: 200px;
  border-radius: 8px 8px 0px 0px;
  opacity: 0.9;
  background:
    url("../avatar.jpg") lightgray 50% / cover no-repeat,
    #d9d9d9;
}

.overview-section {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;

  min-width: 400px;
  /* height: 200px; */

  margin-bottom: 0px;

  flex: 0 0 33.3333%;
}

.overview-video,
.overview-video video {
  object-fit: cover;
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  /* opacity: 0.9; */
  /* background: */
  /* url(../kaba-social.jpg) lightgray 50% / cover no-repeat, */
  /* #d9d9d9; */
}

.overview-video video {
  margin-bottom: -5px;
}

.overview-text .body .text {
  color: rgba(255, 255, 255, 0.3);
  /* display: none; */
  /* height: 50px; */
}

.overview-text {
  background: var(--black-60, #1119);
  backdrop-filter: blur(6px);
  border-radius: 0 0 8px 8px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  padding: 24px;
  display: flex;
  box-shadow: 0 14px 6px 4px #0000001a;
  flex-direction: column;
  flex-wrap: wrap;
}

.overview-section:hover .overview-text {
  background: #ffffff14;
}

.title {
  text-align: left;
  flex: 1 0 0;
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--slime-100, #deeb39);
  leading-trim: both;
  text-edge: cap;

  /* Content/Small/Regular - 16 */
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
}

.title-icon {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.title-icon svg {
  fill: var(--slime-100, #deeb39);
  width: 19.5px;
  height: 19.5px;
  flex-shrink: 0;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
}

.body .text {
  display: flex;
  padding-bottom: 8px;
  align-items: flex-start;
  text-align: left;
  gap: 24px;

  overflow: hidden;
  color: var(--white-100, #fff);
  leading-trim: both;
  text-edge: cap;
  text-overflow: ellipsis;

  /* Content/Small/Regular - 16 */
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
}

.body .header {
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;

  overflow: hidden;
  color: var(--white-100, #fff);
  leading-trim: both;
  text-edge: cap;
  text-overflow: ellipsis;

  font-family: "Space Grotesk";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.footer {
  color: var(--white-100, #fff);
  leading-trim: both;
  text-edge: cap;

  font-family: "Space Mono";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.24px;
  text-transform: uppercase;

  text-align: left;

  display: flex;
  justify-content: flex-end;
  gap: 15px;
  align-self: stretch;
}

.footer .text {
  display: flex;
  height: 40px;
  flex-direction: column;
  justify-content: center;

  flex: 1 0 0;
}

.footer .button {
  color: #000;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--slime-100, #deeb39);
  max-width: 150px;
  min-width: 36px;
  height: 36px;
  border: none;
}

.footer .button a,
.footer .button a svg {
  width: 20px;
  height: 20px;
  /* color: #000; */
  /* fill: #000; */
  /* stroke: #000; */
}

.preview {
  width: 90%;
  height: 75vh;
  background-image: url(../preview.png);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

/* video::-webkit-media-controls { */
/* display: none !important; */
/* } */
/* video::-webkit-media-controls-enclosure { */
/* display: none !important; */
/* } */

video {
  width: 100%;
}

#path-one-one {
  margin-bottom: -50px;
}

.fade-in {
  animation: fade-in 2s linear infinite;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.bounce2 {
  animation: bounce2 2s ease infinite;
}
@keyframes bounce2 {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.video-wrapper {
  display: flex;
  /* overflow: hidden; */
  position: relative;

  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 0;
}

#btn-find-out-two {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

video#kaba-main-video {
  box-shadow: 0px 10px 22px 3px rgba(0, 0, 0, 0.48);
  border-radius: 10px;
  display: block;
  position: relative;
  /* margin: auto; */
  max-width: 70% !important;

  margin-top: 40px;

  max-height: 800px;
  margin-bottom: 60px;
  z-index: 10;
}

#video-canvas {
  /* filter: sepia(#000, 100%), blur(30px); */
  /* filter: blur(30px); */
  opacity: 0.3;
  width: 100%;
  height: 100%;
  max-height: 1000px;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  /* filter: drop-shadow(16px 16px 20px blue); */
  /* filter: grayscale(50%); */
  /* filter: ; */

  filter: blur(100px);

  -webkit-mask-image: radial-gradient(circle, black 90%, transparent 90%);
  mask-image: radial-gradient(circle, black 90%, transparent 90%);
  /* clip-path: circle(70% at center); */
}

.section-split {
  flex-flow: column;
  place-content: center;
  align-items: center;
  /* margin-bottom: 100px; */
  display: flex;
  position: relative;
  padding-bottom: 100px;
}

.section-split div {
}

.section-split h1 {
  font-size: 4rem;

  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
}

.section-split div {
  color: #ffffff54;
  flex-flow: row;
  place-content: center;
  align-items: center;
  font-size: 2rem;
  display: flex;
  position: relative;
}

.preview-omnibar {
  width: 40vw;
  margin-bottom: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 16px;
  background: linear-gradient(
      180deg,
      rgba(17, 17, 17, 0.5) 0%,
      rgba(119, 119, 119, 0) 100%
    ),
    rgba(17, 17, 17, 0.8);
  backdrop-filter: blur(6px);
  overflow: hidden;
}

.preview-omnibar img {
  width: 24px;
}

.preview-omnibar-top {
  background-color: var(--chartreuse);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  padding: 16px;
}

.preview-omnibar-bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  padding: 24px 24px;
  font-family: "Space Grotesk", sans-serif;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
}

.preview-omnibar-bottom img:first-of-type {
  width: 40px;
}

.preview-omnibar-bottom h5 {
  color: var(--light-gray);
  font-family: "Space Mono", monospace;
  text-transform: uppercase;
  font-weight: 200;
  font-size: 0.8rem;
}

.preview-omnibar-bottom hr {
  height: 1px;
}

.preview-omnibar-bottom p {
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  max-width: 100% !important;
}

.preview-omnibar-ideas {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 32px;
}

.preview-omnibar-idea {
  width: 10vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.7rem;
  font-weight: 200;
  gap: 8px;
}

.preview-omnibar-idea img {
  width: 24px !important;
}

.preview-omnibar-text {
  width: 100%;
  color: var(--black);
  font-family: "Space Grotesk", sans-serif;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

#s-one {
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

#s-one h1 {
  font-family: "Space Grotesk", sans-serif;
  font-size: 4em;
  font-weight: 400;
  line-height: 76.56px;
  letter-spacing: -0.02em;
  text-align: center;
  margin: 0;
  padding: 0;
  opacity: 0px;
}

#s-one p {
  opacity: 0px;
  font-family: "Space Grotesk", sans-serif;
  font-size: 1.5em;
  font-weight: 400;
  max-width: 40%;
  line-height: 130%;
  text-align: center;
}

#s-two {
  position: fixed;
  top: 0;
  left: 0;
  transform: scale(0.25);
  opacity: 0;
}

#s-two strong {
  font-weight: 700;
}

#s-two p {
  color: #fff;
  text-align: center;
  font-family: "Space Grotesk", sans-serif;
  font-size: 3em;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 52px */
  max-width: 60%;
}

#btn-scroll-down {
  cursor: pointer !important;
  z-index: 999999;

  width: 70px;
  height: 100px;
  flex-shrink: 0;
}

#s-last {
  z-index: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

#s-last p {
  text-align: center;
  font-family: "Space Grotesk", sans-serif;
  font-size: 2.1em;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 31.2px */
  max-width: 60%;
}

#s-value-props {
  position: relative;
  top: 0;
}

#s-value-props section {
  gap: 1.3em;
  width: 30vw;
  height: auto;
  display: flex;
  position: absolute;
  left: 60vw;
  top: 0;
  align-items: flex-start;
  align-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  transform-origin: center;
  margin-top: 40vh;
}

.typed-cursor.typed-cursor--blink {
  animation: typedjsBlink 0.7s infinite;
  -webkit-animation: typedjsBlink 0.7s infinite;
  animation: typedjsBlink 0.7s infinite;
}

.typed-cursor {
  display: none;
}

.value-prop-title {
  text-transform: uppercase;
  font-weight: 700;
  /* font-size: 1.4rem; */
  font-size: 2.3rem;
}

.value-prop-body {
  font-weight: 300;
  font-size: 1.6rem;
  color: #9e9d9f;
}

.value-prop-progress-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.value-prop-progress-container-mobile {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.value-prop-progress {
  background-color: #6e6d70;
  width: 40px;
  height: 2px;
}

.progress-active {
  background-color: #deeb39;
  height: 100%;
}

#welcome {
  position: relative;
}

#contact {
  margin-top: var(--content-margin-top);
  margin-bottom: var(--content-margin-bottom);
  width: calc(100%-48px);
  max-width: var(--max-layout-width);
  position: relative;
  display: grid;
  grid-template-columns: var(--grid-column);
  grid-template-rows: auto;
  min-height: 50vh;
  gap: var(--grid-column-gap);
}

#contact .kaba-contact {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 48px;
}

#contact .kaba-contact-message {
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: "Space Grotesk", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 57.2px */
  display: flex;
  align-items: flex-end;
  position: relative;
  flex-direction: column;
  max-width: 65%;
  justify-self: end;
}

#contact .kaba-contact-title {
  color: var(--light-gray);
  font-family: "Space Mono", monospace;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  text-transform: uppercase;
  margin-bottom: 15px;
}

#contact .kaba-contact-value {
  color: #fff;
  font-family: "Space Grotesk", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 26px */
}

:root {
    --max-layout-width: 1560px;
    --content-margin-top: 200px;
    --content-margin-bottom: 150px;
    --grid-column-gap: 72px;
    --grid-column: 0.65fr 0.35fr;
    --circle-copy-width: 30vw;
    --mobile-content-width: width: 85vw;

    --light-gray: rgba(255, 255, 255, 0.4);
    --chartreuse: #deeb39;
    --black: #111;
}